import { useApolloClient } from '@apollo/client';
import { IFeedbackModal } from 'components/layout/FeedbackModal';
import { axiosRestApi } from 'config/axiosRestApi';
import { CF_AUTH_COOKIE } from 'constants/api';
import { useFeedbackModal, useSession } from 'providers';
import { useFormManager } from 'providers/FormManagerProvider';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCookie } from 'utils/cookie';
import { logError } from 'utils/logError';

export const useLogout = () => {
  const [loading, setLoading] = useState(false);

  // Do not destructure apollo client
  // https://github.com/apollographql/apollo-client/issues/4970#issuecomment-1004438065
  const apolloClient = useApolloClient();
  const {
    clearSessionUser,
    setEnableCloudflareAutoLogin,
    setLogoutInProgress,
    clearSessionFirebase,
  } = useSession();

  const { dirtyFormRef, resetFormManager, confirmationProps } =
    useFormManager();
  const navigate = useNavigate();
  const cloudflareCookie = getCookie(CF_AUTH_COOKIE);
  const { openFeedbackModal } = useFeedbackModal();

  const logoutRequest = useCallback(async () => {
    if (apolloClient.clearStore) {
      await apolloClient.clearStore().catch((error: unknown) => {
        logError({ error });
      });
    }

    await axiosRestApi.post('/logout').catch((error: unknown) => {
      logError({ error });
    });
  }, [apolloClient.clearStore, cloudflareCookie]);

  const handleLogout = useCallback(async () => {
    setLoading(true);
    setEnableCloudflareAutoLogin(false);
    setLogoutInProgress(true);
    try {
      await logoutRequest().then(async () => {
        // clear cookies and storage
        await clearSessionUser().then(() => {
          setLoading(false);
          // navigate to landing page
          navigate('/');
          setLogoutInProgress(false);
        });
      });
    } catch (error) {
      setLoading(false);
      setLogoutInProgress(false);
      throw error;
    }
  }, [clearSessionUser, logoutRequest, navigate]);

  const expireSession = useCallback(async () => {
    setLoading(true);
    setLogoutInProgress(true);
    try {
      await logoutRequest().then(async () => {
        await clearSessionFirebase();
      });
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
      setLogoutInProgress(false);
    }
  }, [clearSessionUser, logoutRequest, navigate]);

  const openLogoutConfirmation = useCallback(() => {
    let feedbackModalOptions: IFeedbackModal = {
      type: 'warning',
      title: 'UNSAVED CHANGES',
      message: 'You have unsaved changes. Would you like to discard them?',
      cancelBtn: {
        label: 'Cancel',
      },
      submitBtn: {
        label: 'Discard',
        onClick: () => {
          handleLogout().catch((error: unknown) => {
            logError({ error });
          });
          resetFormManager();
        },
      },
    };

    if (dirtyFormRef.current === 'saveable' && confirmationProps) {
      const { cancelLabel, discardLabel, onDiscard, additionalOptions } =
        confirmationProps;

      feedbackModalOptions = {
        ...feedbackModalOptions,
        cancelBtn: {
          label: cancelLabel,
        },
        submitBtn: {
          label: discardLabel,
          onClick: onDiscard,
        },
        additionalOptions,
      };
    }

    openFeedbackModal(feedbackModalOptions);
  }, [
    handleLogout,
    resetFormManager,
    dirtyFormRef.current,
    confirmationProps,
    openFeedbackModal,
  ]);

  const logout = useCallback(() => {
    if (dirtyFormRef.current) {
      openLogoutConfirmation();
      return;
    }
    handleLogout().catch((error: unknown) => {
      logError({ error });
    });
  }, [dirtyFormRef.current, openLogoutConfirmation, handleLogout]);

  return { logout, handleLogout, loading, expireSession };
};
