import * as Sentry from '@sentry/react';
import SessionManager from 'components/auth/SessionManager/SessionManager';
import SessionExpiryModal from 'components/auth/signIn/SessionExpiryModal';
import PageBase from 'components/layout/PageBase/PageBase';
import Page404 from 'components/pages/Page404/Page404';
import { ContainerLoading } from 'components/system/ContainerLoading';
import { ToastContainer } from 'components/system/Toast';
import { SessionProvider } from 'providers';
import { FormManagerProvider } from 'providers/FormManagerProvider';
import ModalRenderer from 'providers/ModalProvider/ModalRenderer';
import { lazy, Suspense, useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Navigate,
  Outlet,
  Route,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENV,
  release: process.env.REACT_APP_VERSION
    ? `web-app-frontend@${process.env.REACT_APP_VERSION}`
    : undefined,
  enableTracing: process.env.REACT_APP_SENTRY_ENABLE_TRACING === 'true',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
});

const LandingPage = lazy(
  () => import('components/layout/LandingPage/LandingPage'),
);
const WelcomePage = lazy(() => import('components/auth/welcome/WelcomePage'));
const CreateExam = lazy(() => import('components/pages/CreateExam/CreateExam'));
const CreatePatient = lazy(
  () => import('components/pages/CreatePatient/CreatePatient'),
);
const MyAccount = lazy(() => import('components/pages/MyAccount/MyAccount'));
const LoadingPredictions = lazy(
  () => import('components/pages/Validation/LoadingPredictions'),
);
const Dashboard = lazy(() => import('components/pages/Dashboard/Dashboard'));
const ValidationView = lazy(
  () => import('components/pages/Validation/ValidationView'),
);
const PatientsView = lazy(
  () => import('components/pages/PatientsView/PatientsView'),
);
const StudyManager = lazy(
  () => import('components/pages/StudyManager/StudyManager'),
);
const About = lazy(() => import('components/pages/About/About'));

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <FormManagerProvider>
          <SessionProvider>
            <SessionManager />
            <SessionExpiryModal />
            <ModalRenderer />
            <ToastContainer />
            <Outlet />
          </SessionProvider>
        </FormManagerProvider>
      }
    >
      <Route
        path="/"
        element={
          <Suspense fallback={<ContainerLoading />}>
            <LandingPage />
          </Suspense>
        }
      />

      <Route
        path="welcome"
        element={
          <Suspense fallback={<ContainerLoading />}>
            <WelcomePage />
          </Suspense>
        }
      />
      <Route path="app" element={<PageBase />}>
        <Route path="" element={<Navigate to="dashboard" />} />
        <Route
          path="dashboard"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <Dashboard />
            </Suspense>
          }
        />
        <Route
          path="my-account"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <MyAccount />
            </Suspense>
          }
        />
        <Route
          path="create-ai-exam"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <CreateExam />
            </Suspense>
          }
        />
        <Route
          path="create-patient"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <CreatePatient />
            </Suspense>
          }
        />
        <Route
          path="loading-predictions"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <LoadingPredictions />
            </Suspense>
          }
        />
        <Route path="validation/*">
          <Route
            path=":examId/:notificationId"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <ValidationView />
              </Suspense>
            }
          />
          <Route
            path=":examId/*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <ValidationView />
              </Suspense>
            }
          />
        </Route>

        <Route path="patients-view/*">
          <Route
            path=":patientId/*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <PatientsView />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<ContainerLoading />}>
                <PatientsView />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="study-manager"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <StudyManager />
            </Suspense>
          }
        />

        <Route
          path="about"
          element={
            <Suspense fallback={<ContainerLoading />}>
              <About />
            </Suspense>
          }
        />
      </Route>

      <Route path="*" element={<Page404 />} />
    </Route>,
  ),
);
