import {
  ApolloError,
  ServerError,
  useMutation,
  useQuery,
} from '@apollo/client';
import React, { MouseEvent, useEffect, useState } from 'react';

import { useSession } from 'providers';
import { Notification } from 'types/mongoose-types';
import { logError } from 'utils/logError';
import { isSessionExpired } from 'utils/sessionExpiry';
import {
  GET_USER_NOTIFICATIONS_QUERY,
  NOTIFICATIONS_SUBSCRIPTION,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from './notifications.api';
import {
  INotificationsData,
  INotificationsSubscriptionData,
  INotificationsVars,
  TOperationProps,
  TRemoveNotificationData,
} from './notifications.types';

const countNewNotifications = (notifications: Notification[]) =>
  notifications.filter((notification) => !notification.read).length;

export const useNotifications = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [newNotifications, setNewNotifications] = useState<number>(0);
  const [currentNotifications, setCurrentNotifications] = useState<
    Notification[]
  >([]);
  const [errorCount, setErrorCount] = useState(0);
  const { user, getExpiresIn, firebaseId } = useSession();

  const { subscribeToMore, startPolling, stopPolling } = useQuery<
    INotificationsData,
    INotificationsVars
  >(GET_USER_NOTIFICATIONS_QUERY, {
    variables: {
      filter: {
        userId: user?._id,
      },
    },
    skip: isSessionExpired(getExpiresIn()) || !firebaseId,
    skipPollAttempt: () => !firebaseId,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({
      userNotifications,
    }: {
      userNotifications: Notification[];
    }) => {
      if (errorCount > 0) {
        setErrorCount(0);
        stopPolling();
        startPolling(5000);
      }
      setCurrentNotifications(userNotifications);
      setNewNotifications(countNewNotifications(userNotifications));
    },
    onError: (error) => {
      const currentCount = errorCount + 1;

      // pollingInterval with exponential backoff, max 10 minutes
      const pollingInterval = Math.min(
        Math.pow(2, currentCount) * 1000,
        600000,
      );

      stopPolling();
      startPolling(pollingInterval);
      setErrorCount(currentCount);
      logError({ error, ignoreSentry: true });
      return;
    },
  });

  const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
    onCompleted: ({
      updateNotification: {
        record: { _id },
      },
    }) => {
      const updatedNotifications = currentNotifications.map((notification) =>
        notification._id === _id
          ? { ...notification, read: true }
          : notification,
      );
      setCurrentNotifications(updatedNotifications);
      setNewNotifications(countNewNotifications(updatedNotifications));
    },
  });

  const [removeNotification]: [
    TOperationProps,
    {
      data?: TRemoveNotificationData;
      loading?: boolean;
      error?: ApolloError | ServerError;
    },
  ] = useMutation(REMOVE_NOTIFICATION, {
    onCompleted: ({
      removeNotification: {
        record: { _id },
      },
    }) => {
      const updatedNotifications = currentNotifications.filter(
        (notification) => notification._id !== _id,
      );
      setCurrentNotifications(updatedNotifications);
      setNewNotifications(countNewNotifications(updatedNotifications));
    },
  });

  const getNewNotifications = React.useCallback(() => {
    subscribeToMore<INotificationsSubscriptionData, INotificationsVars>({
      document: NOTIFICATIONS_SUBSCRIPTION,
      variables: {
        filter: {
          userId: user?._id,
        },
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data.newNotification) {
          return prev;
        }

        const newFeedItem = subscriptionData.data.newNotification;

        const newData = Object.assign({}, prev, {
          userNotifications: [newFeedItem, ...prev.userNotifications],
        });

        setNewNotifications(newNotifications + 1);

        return newData;
      },
    });
    startPolling(5000);
  }, [user, subscribeToMore]);

  useEffect(() => {
    getNewNotifications();
  }, [getNewNotifications]);

  const areNotificationsOpen = Boolean(anchorEl);

  const handleMenuButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(areNotificationsOpen ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    areNotificationsOpen,
    anchorEl,
    handleMenuButtonClick,
    handleClose,
    newNotifications,
    notifications: currentNotifications,
    updateNotification,
    removeNotification,
  };
};
