import * as yup from 'yup';
import { FORM_ERROR_MESSAGE } from 'yupHelper';
import { formatAcceptedFilesMessage } from './helpers/formatAcceptedFilesMessage';
import { validateFileSize } from './helpers/validateFileSize';

export const getValidationSchema = (
  deviceName: string,
  acceptedExtensions?: string,
) =>
  yup.object().shape({
    files: yup
      .mixed<FileList>()
      .test(
        'required',
        FORM_ERROR_MESSAGE.REQUIRED,
        (files: FileList | undefined) => {
          if (!files) {
            return false;
          }
          return files.length > 0;
        },
      )
      .test(
        'extension',
        formatAcceptedFilesMessage(acceptedExtensions),
        (files: FileList | undefined) => {
          if (!acceptedExtensions) {
            return true;
          }

          if (!files) {
            return false;
          }

          const filesArray = Array.from(files);
          const acceptedExtensionsArray = acceptedExtensions.split(',');

          const hasInvalidExtensions = filesArray.some((file: File) => {
            const extension = file.name.substring(file.name.lastIndexOf('.'));
            return !acceptedExtensionsArray.includes(extension);
          });

          return !hasInvalidExtensions;
        },
      )
      .test(
        'file name max length',
        FORM_ERROR_MESSAGE.FILE_NAME_MAX_LENGTH,
        (files: FileList | undefined) => {
          if (!files) {
            return false;
          }
          const filesArray = Array.from(files);

          const hasInvalidFileNameLength = filesArray.some((file: File) => {
            return file.name.length > 100;
          });

          return !hasInvalidFileNameLength;
        },
      )
      .test(
        'file size',
        formatAcceptedFilesMessage(acceptedExtensions),
        validateFileSize,
      ),
  });
