import { useQuery } from '@apollo/client';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  CDN_EXPIRATION_LOCAL_STORAGE,
  CDN_SIGNED_COOKIE,
  FB_AUTH_EXPIRATION_LOCAL_STORAGE,
  FB_ID_LOCAL_STORAGE,
} from 'constants/api';
import dayjs from 'dayjs';
import { deleteCookie } from 'utils/cookie';
import { logError } from 'utils/logError';
import { GET_USER } from './sessionProvider.api';
import { useCdnSessionCookie } from './sessionProvider.hooks';
import {
  ISessionContext,
  ISessionProvider,
  IUserQuery,
  SessionUser,
} from './sessionProvider.types';

const SessionContext = createContext<ISessionContext>({} as ISessionContext);

export const useSession = (): ISessionContext => useContext(SessionContext);

const SessionProvider = ({ children }: ISessionProvider): JSX.Element => {
  // DB user
  const [user, setUser] = useState<SessionUser | undefined>();
  // enable cloudflare auth
  const [enableCloudflareAutoLogin, setEnableCloudflareAutoLogin] =
    useState<boolean>(true);
  // logout in progress
  const [logoutInProgress, setLogoutInProgress] = useState<boolean>(false);

  const hasInstitutionAccessScope = Boolean(
    user?.institution &&
      'dataAccessScope' in user.institution &&
      user.institution.dataAccessScope === 'institution',
  );

  const getExpiresIn = () =>
    Number(sessionStorage.getItem(FB_AUTH_EXPIRATION_LOCAL_STORAGE));
  const storedFirebaseId = sessionStorage.getItem(FB_ID_LOCAL_STORAGE);
  const [firebaseId, setFirebaseId] = useState<string | null>(storedFirebaseId);

  // user query
  const { refetch, data, loading } = useQuery<IUserQuery>(GET_USER, {
    variables: {
      filter: { firebaseId },
    },
    skip: Boolean(!firebaseId || user),
    onCompleted: ({ user: responseUser }) => {
      if (responseUser.timezone) {
        dayjs.tz.setDefault(responseUser.timezone);
      }
    },
    onError: (error) => {
      logError({ error });
    },
  });

  const updateExpiresIn = (updatedExpiresIn: string) => {
    sessionStorage.setItem(FB_AUTH_EXPIRATION_LOCAL_STORAGE, updatedExpiresIn);
  };

  const updateFirebaseId = (updatedFirebaseId: string) => {
    sessionStorage.setItem(FB_ID_LOCAL_STORAGE, updatedFirebaseId);
    setFirebaseId(updatedFirebaseId);
  };

  const clearSessionStorage = async () => {
    sessionStorage.removeItem(FB_AUTH_EXPIRATION_LOCAL_STORAGE);
    sessionStorage.removeItem(FB_ID_LOCAL_STORAGE);
    // delete apollo cache
    localStorage.removeItem('apollo-cache-persist');
    // delete default technology
    localStorage.removeItem('defaultTechnology');
    // delete CDN expiration date
    localStorage.removeItem(CDN_EXPIRATION_LOCAL_STORAGE);
    // deleteCookie(FB_AUTH_COOKIE);
    deleteCookie(CDN_SIGNED_COOKIE);
    // Ensure all cleanups are processed
    await new Promise((resolve) => setTimeout(resolve, 0));
  };

  const clearSessionFirebase = async () => {
    setFirebaseId(null);
    await clearSessionStorage();
  };

  const clearSessionUser = async () => {
    setUser(undefined);
    setFirebaseId(null);
    await clearSessionStorage();
  };

  useEffect(() => {
    if (firebaseId && !user) {
      void refetch();
    }
  }, [firebaseId, user, refetch]);

  useEffect(() => {
    if (data?.user) {
      // update user context when cloudflare identity email changes
      setUser(data.user);
    }
  }, [data]);

  // Get/revalidate CDN cookie
  useCdnSessionCookie(user);

  // user context values
  const values = useMemo<ISessionContext>(
    () => ({
      user,
      hasInstitutionAccessScope,
      setUser,
      getExpiresIn,
      updateExpiresIn,
      firebaseId,
      updateFirebaseId,
      clearSessionUser,
      clearSessionFirebase,
      loading,
      enableCloudflareAutoLogin,
      setEnableCloudflareAutoLogin,
      logoutInProgress,
      setLogoutInProgress,
    }),
    [
      user,
      hasInstitutionAccessScope,
      setUser,
      getExpiresIn,
      updateExpiresIn,
      firebaseId,
      updateFirebaseId,
      clearSessionUser,
      clearSessionFirebase,
      loading,
      enableCloudflareAutoLogin,
      setEnableCloudflareAutoLogin,
      logoutInProgress,
      setLogoutInProgress,
    ],
  );

  return (
    <SessionContext.Provider value={values}>{children}</SessionContext.Provider>
  );
};

export default SessionProvider;
