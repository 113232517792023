import { useApolloClient } from '@apollo/client';
import { useSession } from 'providers';
import { useEffect } from 'react';
import { useIdleSessionExpiration } from './useIdleSessionExpiration';
import { useSessionExpiration } from './useSessionExpiration';

const SessionManager = () => {
  const { user } = useSession();
  const apolloClient = useApolloClient();

  useSessionExpiration();
  useIdleSessionExpiration();

  // Refetch active queries when user logs back in after session expiration
  useEffect(() => {
    if (user) {
      void apolloClient.reFetchObservableQueries();
    }
  }, [user]);

  return <></>;
};

export default SessionManager;
