import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation } from 'react-router-dom';

import { useSession, useSessionExpiryModal } from 'providers';
import { useLogout } from '../hooks/useLogout';

const TIME_TO_EXPIRE =
  1000 * 60 * (Number(process.env.REACT_APP_INACTIVITY_EXPIRATION) ?? 5);

export const useIdleSessionExpiration = () => {
  const apolloClient = useApolloClient();
  const { firebaseId } = useSession();
  const { openSessionExpiryModal, isModalOpen } = useSessionExpiryModal();
  const { pathname } = useLocation();
  const { expireSession, logout } = useLogout();

  const isLoginPage = pathname === '/';

  const handleIdle = () => {
    if (!firebaseId) {
      return;
    }

    if (isLoginPage) {
      logout();
    } else {
      void expireSession();
    }

    if (!isModalOpen) {
      openSessionExpiryModal();
    }

    void apolloClient.clearStore();
  };

  const { start, pause } = useIdleTimer({
    timeout: TIME_TO_EXPIRE,
    onIdle: handleIdle,
    startManually: true,
    crossTab: true,
    stopOnIdle: true,
  });

  useEffect(() => {
    if (firebaseId) {
      start();
      return;
    }

    pause();
  }, [firebaseId]);
};
